import { useState } from "react";
import LoginForm from "../../components/auth/LoginForm";
import SignUpForm from "../../components/auth/SignupForm";
import OverlaySpinner from "../../components/utils/spinners/OverlaySpinner/OverlaySpinner";
import "./Login.css";

export default function Page() {
  const [showSignup, setShowSignup] = useState<boolean>(false);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);

  return (
    <div className="flex w-11/12 sm:w-3/12 sm:min-w-[480px]  mx-auto perspective">
      <div
        className={`form-container flex h-full mt-8 ${
          showSignup ? "flipped" : ""
        }`}
      >
        <div className="form-side front border-2 border-slate-200/[0.5] shadow-lg rounded-lg p-4 sm:p-8 bg-black/[0.6]">
          {showSpinner ? <OverlaySpinner /> : null}
          <LoginForm showSpinner={(val: boolean) => setShowSpinner(val)} />
          <div className="flex text-[12px] mt-4 items-baseline justify-center">
            Not Registered yet! <span className="text-[13px] mx-1">|</span>
            <span
              className="text-[10.5px] cursor-pointer bg-[#e923f4] font-semibold py-[0.25rem] px-1 hover:scale-110 hover:bg-[#cd1fd7] rounded-lg tracking-wide"
              onClick={() => setShowSignup(true)}
            >
              SignUp
            </span>
          </div>
        </div>
        <div className="form-side back border-2 border-slate-200/[0.5] shadow-lg rounded-lg p-4 sm:p-8 bg-black/[0.6]">
          {showSpinner ? <OverlaySpinner /> : null}
          <SignUpForm
            onSuccess={() => setShowSignup(false)}
            showSpinner={(val: boolean) => setShowSpinner(val)}
          />
          <div className="flex text-[11px] mt-4 items-baseline justify-center">
            Already Registered <span className="text-[13px] mx-1">|</span>
            <span
              className="text-[10.5px] cursor-pointer bg-[#e923f4] font-semibold py-[0.25rem] px-1 hover:scale-110 hover:bg-[#cd1fd7] rounded-lg tracking-wide"
              onClick={() => setShowSignup(false)}
            >
              Login
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
