import homeIcon from "../../assets/icons/homeIcon.svg";
import { useNavigate } from "react-router-dom";
import { Games } from "./constants/games";

export default function SideNav() {
  const navigate = useNavigate();

  const reditectTo = (path: string) => {
    navigate(path);
  };

  return (
    <div className="flex flex-col w-full">
      <div className="flex w-full items-baseline p-4">
        <div
          className="flex flex-col cursor-pointer justify-center items-center w-min p-1"
          onClick={() => reditectTo("/lobby")}
        >
          <img src={homeIcon} alt="Lobby" />
          <div className="text-xs text-white font-bold">Lobby</div>
        </div>
        <div className="text-2xl mx-auto font-extrabold text-white">
          Slot Games
        </div>
      </div>
      <div className="flex flex-col p-3">
        {Games.map((_g) => (
          <div className="flex flex-col w-[95%] mt-2 hover:scale-[1.07]">
            <img
              className="cursor-pointer rounded-xl border-2 shadow-md"
              src={_g.gameImage}
              alt={_g.gameId}
              onClick={() => reditectTo(`/slot-games/${_g.gameId}`)}
            />
            <div className="text-xs mt-1 ml-2 neutral-100 font-bold">
              {_g.gameName}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
