import vegas777Img from "../../../assets/vegas777.jpg";
import fruit777Img from "../../../assets/fruit777.png";
// import fruit777Img_2 from "../../../assets/fruit777_2.png";
// import fruit777Img_3 from "../../../assets/fruit777_3.jpg";
import kenoImg from "../../../assets/keno.jpg";

export const Games = [
    {
        gameId: "vegas777",
        gameName: "Vegas 777",
        gameImage: vegas777Img,
        buildType: 'webgl'
    },
    {
        gameId: "fruit777",
        gameName: "Fruit 777",
        gameImage: fruit777Img,
        buildType: 'webgl'
    },
    // {
    //     gameId: "fruit777inlogin",
    //     gameName: "Fruit 777 Int.Login",
    //     gameImage: fruit777Img_2,
    //     buildType: 'webgl'
    // },
    // {
    //     gameId: "fruit777apiskip",
    //     gameName: "Fruit 777 Api Skip",
    //     gameImage: fruit777Img_3,
    //     buildType: 'webgl'
    // },
    {
        gameId: "keno",
        gameName: "Keno",
        gameImage: kenoImg,
        buildType: 'webgl'
    },
];