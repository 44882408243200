import React from "react";
import { useParams } from "react-router-dom";
import SideNav from "../../components/utils/Sidenav";
import UnityWebGLPlayer from "../../components/gamePlayer/UnityWebGLPlayer";

const SlotGame: React.FC = () => {
  const { gameId } = useParams<{ gameId: string }>();
  const { innerWidth, innerHeight } = window;

  return (
    <div className="flex h-full w-full">
      {innerWidth > innerHeight && (
        <div
          className="hidden sm:flex w-[18%] min-w-[240px] max-w-[340px] bg-black/[0.75]"
          style={{ boxShadow: "2px 0 5px rgba(0, 0, 0, 0.5)" }}
        >
          <SideNav></SideNav>
        </div>
      )}
      <div
        className={`flex w-full h-full p-0 ${
          innerWidth > innerHeight && "sm:p-12"
        }`}
      >
        <div
          className={`flex h-full w-full ${
            innerWidth > innerHeight &&
            "sm:w-10/12 sm:justify-between sm:mx-auto"
          }`}
        >
          {gameId ? (
            <UnityWebGLPlayer gameId={gameId}></UnityWebGLPlayer>
          ) : (
            <>Please Select a game to play</>
          )}
        </div>
      </div>
    </div>
  );
};

export default SlotGame;
