import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./App.css";
import Login from "./pages/auth/Login";
import Lobby from "./pages/lobby/Lobby";
import SlotGame from "./pages/slotGames/Game";
import NotFound from "./pages/NotFound";

const App: React.FC = () => {
  const token = sessionStorage.getItem("token");

  return (
    <Router>
      <div className="App bg-[url('./assets/mob-bg.jpg')] sm:bg-[url('./assets/web-bg.jpg')]">
        <Routes>
          <Route
            path="/"
            element={
              token ? (
                <Navigate to="/lobby" replace />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/lobby" element={<Lobby />} />
          <Route path="/slot-games/:gameId" element={<SlotGame />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
