import { useNavigate } from "react-router-dom";
import { Games } from "../../components/utils/constants/games";

export default function Page() {
  const navigate = useNavigate();

  const redirectToSlotGames = (gameId: string) => {
    navigate(`/slot-games/${gameId}`);
  };

  return (
    <div className="flex h-full flex-col rounded-md sm:w-11/12 p-2 sm:p-4 sm:mx-auto">
      <div className="flex w-full justify-center text-3xl mb-4 font-bold mt-2 sm:mt-4">
        Slot Games
      </div>
      <div className="flex text-base font-semibold sm:mb-2 pl-4">
        Top Picks for you
      </div>
      <div className="flex flex-wrap p-1 pt-0 sm:p-2 max-h-[90vh] overflow-visible">
        {Games.map((_g) => (
          <div className="flex flex-col w-[48%] border-2 ml-2 hover:scale-[1.075] mt-4 sm:w-[22.5%] sm:ml-[18px] rounded-xl bg-black/[0.65]">
            <img
              className="cursor-pointer rounded-t-xl shadow-md sm:min-h-[150px] sm:max-h-[150px]"
              src={_g.gameImage}
              alt={_g.gameId}
              onClick={() => redirectToSlotGames(_g.gameId)}
            />
            <div className="text-xs my-1 ml-2 text-neutral-100 font-bold tracking-wide">
              {_g.gameName}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
