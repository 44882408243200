import React, { useEffect, useRef, useState } from "react";
import { Games } from "../utils/constants/games";
import "./GamePlayer.css";

export default function UnityWebGLPlayer(props: { gameId: string }) {
  const { gameId } = props;
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const unityInstanceRef = useRef<any>(null);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);

  const isWebglBuild = (gId: string) => {
    const game = Games.find((_g) => _g.gameId === gId);
    return game?.buildType === "webgl";
  };

  const resizeCanvas = () => {
    const canvas = canvasRef.current;
    const container = containerRef.current;
    if (canvas && container) {
      const { clientWidth, clientHeight } = container;
      const maxSize = Math.max(clientWidth, clientHeight);
      let minSize = Math.min(clientWidth, clientHeight);

      if (clientWidth > clientHeight) {
        minSize = Math.min(minSize, 640);
      }

      canvas.style.width = `${maxSize}px`;
      canvas.style.height = `${minSize}px`;

      if (clientHeight > clientWidth) {
        canvas.style.transform = `rotate(90deg) translate(${
          (maxSize - minSize) / 2
        }px, ${(maxSize - minSize) / 2}px)`;
      } else {
        canvas.style.transform = "none";
      }
      canvas.style.transformOrigin = "center center";
    }
  };

  useEffect(() => {
    setShowSpinner(true);
    const addWebglExt = isWebglBuild(gameId);

    const webglFileExtension = ".unityweb";
    const buildUrl = `/UnityBuilds/${gameId}`;
    const loaderUrl = `${buildUrl}/Build.loader.js`;
    const config = {
      dataUrl: `${buildUrl}/Build.data${addWebglExt ? webglFileExtension : ""}`,
      frameworkUrl: `${buildUrl}/Build.framework.js${
        addWebglExt ? webglFileExtension : ""
      }`,
      codeUrl: `${buildUrl}/Build.wasm${addWebglExt ? webglFileExtension : ""}`,
      streamingAssetsUrl: "StreamingAssets",
      companyName: "DefaultCompany",
      productName: "Vegas7s-3D-Built-in",
      productVersion: "0.1",
    };

    const script = document.createElement("script");

    if (canvasRef.current) {
      resizeCanvas();
      window.addEventListener("resize", resizeCanvas);

      script.src = loaderUrl;
      script.onload = () => {
        if (typeof createUnityInstance !== "undefined" && canvasRef.current) {
          createUnityInstance(canvasRef.current, config, (progress) => {
            setShowSpinner(progress !== 1);
          })
            .then((unityInstance) => {
              unityInstanceRef.current = unityInstance;
              unityInstance.SendMessage(
                "GameManager",
                "OnJSSessionVarReceived",
                sessionStorage.getItem("token")
              );
            })
            .catch((message) => {
              alert(message);
            })
            .finally(() => {
              setShowSpinner(false);
            });
        } else {
          console.error("createUnityInstance is not defined");
        }
      };
      document.body.appendChild(script);
    }

    return () => {
      if (unityInstanceRef.current) {
        unityInstanceRef.current.Quit().then(() => {
          console.log("Unity instance successfully quit.");
        });
      }
      document.body.removeChild(script);
      window.removeEventListener("resize", resizeCanvas);
    };
  }, [gameId]);

  return (
    <div
      id="unity-container"
      className="flex flex-col w-full h-full mt-0"
      ref={containerRef}
    >
      <canvas
        id="unity-canvas"
        className="m-0 sm:mx-auto z-50"
        ref={canvasRef}
      ></canvas>
      {showSpinner ? (
        <div className="flex w-full justify-center p-8 absolute top-[30.5%] sm:top-[32%] sm:left-[7%]">
          <div className="loader"></div>
        </div>
      ) : null}
    </div>
  );
}
